import {
  BooleanInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  email,
  required,
  useGetIdentity
} from 'react-admin';

function FormCompanyUsersToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine || props.validating} />
    </Toolbar>
  );
}

export default function FormCompanyUsers(props) {
  const { identity } = useGetIdentity();

  return (
    <SimpleForm
      {...props}
      
      redirect="list"
    >
      <TextInput fullWidth label="Nome" source="name" validate={[required()]} />
      <TextInput
        autoComplete="off"
        fullWidth
        label="E-mail"
        source="email"
        type="email"
        validate={[required(), email()]}
      />

      <ReferenceInput
        label="Equipe"
        source="team"
        reference="teams"
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty
        filter={{
          $limit: -1
        }}
        emptyText="Não definida"
      >
        <SelectInput
          disabled={props.record.isLeader}
          helperText={
            props.record.isLeader
              ? 'Não é possível alterar a equipe de um líder de equipe'
              : ''
          }
        />
      </ReferenceInput>

      {!!identity && identity.id !== props.record.id && (
        <BooleanInput label="Administrador" source="isAdmin" />
      )}

      {props.record.id && !!identity && identity.id !== props.record.id && (
        <BooleanInput label="Ativo" source="active" />
      )}

      {props.record.id && !!identity && identity.id !== props.record.id && (
        <BooleanInput
          fullWidth
          label="É necessário um novo aceite dos termos de voluntariado"
          source="needsAcceptance"
        />
      )}
    </SimpleForm>
  );
}
